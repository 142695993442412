.css-14yr603-MuiStepContent-root {
    margin-left: 22px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    display: flex;
}

.device_disable{
    color:  #d3d3d33d !important
}

.topic-games::after {
    content: ' 🎮 ';
    padding-left: 5px;
}
.topic-games{
    display: flex
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-zajmyo-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 12px !important;
}