.test_2:after {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, rgb(255, 193, 7) -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}

.test_2::before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(210.04deg, rgb(255, 193, 7) -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
    cursor: pointer;
}

.test_2 {
    background-color: rgb(255, 255, 255);
    color: rgb(54, 65, 82);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    background-image: none;
    border-radius: 12px;
    border: none rgba(144, 202, 249, 0.145);
    overflow: hidden;
    position: relative;
}
.smth{
    flex: 1 1 auto !important;
    min-width: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 3.6px !important;
    margin-bottom: 3.6px !important;
}

.smth_1{
    margin: 0px !important;
    font-size: 1rem !important;
   
    font-weight: 600 !important;
    font-family: Roboto, sans-serif !important;
    line-height: 1.235 !important;
}

.smth_2{
    margin: 4px 0px 0px !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    font-family: Roboto, sans-serif !important;
    line-height: 1.57 !important;
   
}

.pay_block::before{
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    border: 3px solid rgb(255, 229, 127);
    border-radius: 50%;
    top: 145px;
    right: -70px;
}

.pay_block::after{
    color: rgb(54, 65, 82);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    border-radius: 12px;
    background: rgb(255, 248, 225);
    margin-top: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
}

.pay_block{
    color: rgb(54, 65, 82);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    border-radius: 12px;
    background: rgb(255, 248, 225);
    margin-top: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
}

.pay_button:hover{
    background: #ffc107 !important;
}

/* .pay_button_:hover{
    background: none !important;
}
 */

.test {
    cursor: pointer;
}
.test_number::after {
    content: '%';
    position: absolute;
    left: 0;
    top: 3px;
    color: #555;
    padding-left: 5px;
    font: inherit;
  }#my-input-container {
    display: inline-block;
    position: relative;
    font: 12px arial;
  }
  
  #my-input {
    font: inherit;
  }
  
  #my-suffix {
    position: absolute;
    left: 0;
    top: 3px;
    color: #555;
    padding-left: 5px;
    font: inherit;
  }

  .custom_number .MuiInputBase-root  {
border-radius: 10px !important;
}
  

.numeric_custom_div{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: ##000000;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
}

.numeric_custom{
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 1px;
    padding: 8.5px 14px;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    border-radius: 12px;
}
.numeric_custom:focus {
    outline: none !important; 
    border: 1px solid #d7d7d7 !important;
    border-radius: 11px;
    border-color: #719ECE;

}